import { FC } from 'react';

import './DataSourceExistingItem.css';
import { Link } from 'react-router-dom';

export type DataSourceExistingItemProps = {
  id: string;
  engine: string;
  config: Record<string, string>;
  onClick: () => void;
};

export const DataSourceExistingItem: FC<DataSourceExistingItemProps> = ({
  id,
  engine,
  config,
  onClick,
}) => {
  return (
    <Link to={`/connections/${id}/dashboard`}>
      <div className="d-flex data-source-existing-item-wrapper" onClick={onClick}>
        <div className="col-3">
          <h3>Engine</h3>
          <p>{engine}</p>
        </div>
        <div className="col-3">
          <h3>Config</h3>
          <p>{JSON.stringify(config)}</p>
        </div>
      </div>
    </Link>
  );
};
