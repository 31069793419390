import { useMutation } from 'react-query';
import { ConnectionConfig } from '../../components/ConnectionManagement/ConnectionManagement.hook';

const doCreateDataSource = (connection: ConnectionConfig) => {
  const { host, port, password, user, database, engine } = connection;
  return fetch('http://localhost:4000/database_connections', {
    method: 'POST',
    body: JSON.stringify({
      config: {
        host,
        port,
        password,
        user,
        database,
      },
      engine,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to create data source');
    }
    return response.json();
  })
};

export const createDataSource = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isError, error, isLoading, mutate, mutateAsync } = useMutation({
    mutationFn: doCreateDataSource,
  });

  return {
    isError,
    isLoading,
    error,
    mutate,
    mutateAsync
  };
};
