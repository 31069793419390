import { Link } from 'react-router-dom';
import { Typography } from '../../ui-kit/components/Typography/Typography';

export const Navigation = () => {
  return (
    <div className="d-flex flex-column p-4">
      <Typography size={'xs'} weight={'md'} className="mb-3">
        <Link to="/connections">Connections</Link>
      </Typography>

      <Typography size={'xs'} weight={'md'}>
        <Link to="/dashboard">Dashboard</Link>
      </Typography>
    </div>
  );
};
