import { FC } from 'react';
import { BarChart, BarChartProps } from '../../charts/BarChart/BarChart';
import { Typography } from '../../ui-kit/components/Typography/Typography';

import './DashboardWidget.css';

export type DashboardWidgetProps = {
  title: string;
  description: string;
  chartOptions: BarChartProps['option'];
};

export const DashboardWidget: FC<DashboardWidgetProps> = ({ title, description, chartOptions }) => {
  return (
    <div className="dashboard-widget-wrapper">
      <div className="dashboard-title-wrapper">
        <Typography size={'lg'} weight={'rg'} element={'p'}>
          {title}
        </Typography>
        <Typography size={'xs'} weight={'md'}>
          {description}
        </Typography>
      </div>
      <div className="dashboard-chart-wrapper">
        <BarChart option={chartOptions} />
      </div>
    </div>
  );
};
