import { Link, Route, Routes } from 'react-router-dom';
import { Screen } from './components/Screen/Screen';
import { ConnectionManagement } from './components/ConnectionManagement/ConnectionManagement';
import { Dashboard } from './components/Dashboard/Dashboard';

import './index.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Screen />}>
        <Route index element={<ConnectionManagement />} />
        <Route path="connections" element={<ConnectionManagement />} />
        <Route path="connections/:connectionId/dashboard" element={<Dashboard />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
