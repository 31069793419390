import { useMutation } from 'react-query';
import { flatten, uniq } from 'lodash';
import { DashboardWidgetProps } from '../../components/DashboardWidget/DashboardWidget';

const doFetchDashboardData = ({ connectionId, businessInfo, adhocPrompt }: { connectionId: string, businessInfo: string, adhocPrompt: string }) => {
  return fetch(`http://localhost:4000/database_connections/${connectionId}/dashboard`, {
    method: 'POST',
    body: JSON.stringify({
      business_info: businessInfo,
      adhoc_prompt: adhocPrompt,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then<DashboardResponse>((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
};

type DashboardResponse = {
  message: string;
  sectionsWithData: SectionWithData[][];
};

type SectionDataPoint = Record<string, string | number>;

type SectionWithData = {
  name: string;
  description: string;
  chart: {
    type: string;
    'x-axis': string;
    'y-axis': string;
    group_by: string | null;
  };
  sqlQuery?: string;
  data: SectionDataPoint[];
};

const getYAxisKey = (yAxisKey: string) => {
  // return `SUM(\`${yAxisKey}\`)`;
  return yAxisKey;
};

const getChartType = (chartType: string) => {
  switch (chartType) {
    case 'bar chart':
      return 'bar';
    case 'line chart':
      return 'line';

    default:
      return 'bar';
  }
};

const transformWidgetData = (sectionWithData: SectionWithData): DashboardWidgetProps => {
  const {
    name,
    description,
    chart: { 'x-axis': xAxisKey, 'y-axis': yAxisKey, type },
    data,
  } = sectionWithData;

  const MAX_DATA_POINTS = 20;

  const xAxisValues = uniq(
    data.map((dataPoint) => dataPoint[xAxisKey]).filter((data) => !!data),
  ).slice(0, MAX_DATA_POINTS);

  const yAxisValues = xAxisValues.map((category) => {
    const dataItem = data.find((dataItem) => {
      return dataItem[xAxisKey] === category;
    });
    if (dataItem) {
      return dataItem[getYAxisKey(yAxisKey)];
    }
    return 0;
  });

  return {
    title: name,
    description,
    chartOptions: {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: xAxisValues,
        axisLabel: { rotate: 30 },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: yAxisValues,
          type: getChartType(type),
        },
      ],
    },
  };
};

const getWidgetsData = (response?: DashboardResponse): DashboardWidgetProps[] => {
  if (response) {
    return flatten(response.sectionsWithData).map(transformWidgetData);
  }
  return [];
};

export const fetchDashboardData = () => {
  const {
    data: response,
    isLoading,
    isError,
    mutate,
    mutateAsync,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useMutation({
    mutationKey: 'fetchDashboardData',
    mutationFn: doFetchDashboardData,
  });

  console.log('response', response);
  const data = getWidgetsData(response);
  console.log('data', data);

  return {
    mutate,
    mutateAsync,
    data,
    isLoading,
    isError,
  };
};
