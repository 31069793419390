import { FC } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';

export type BarChartProps = {
  option: EChartsOption;
};

export const BarChart: FC<BarChartProps> = ({ option }) => {
  return <ReactECharts option={option} style={{ height: 400 }} />;
};
