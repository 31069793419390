import { Outlet } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';

import './Screen.css';

export const Screen = () => {
  return (
    <div className="d-flex flex-row">
      <Sidebar />
      <div className="screen-content-wrapper">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};
