import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { createDataSource } from '../../mutations/createDataSource/createDataSource';
import { DataSources } from '../../routes/DataSources/DataSources';
import { Button } from '../../ui-kit/components/Button/Button';
import { Input } from '../../ui-kit/components/Input/Input';
import { Select } from '../../ui-kit/components/Select/Select';
import { Typography } from '../../ui-kit/components/Typography/Typography';
import { useConnectionManagement } from './ConnectionManagement.hook';

type NotificationType = 'success' | 'info' | 'warning' | 'error';


export const ConnectionManagement = () => {
  const { isError, error, isLoading, mutateAsync } = createDataSource();
  const { connection, setConnection } = useConnectionManagement();
  const [api, contextHolder] = notification.useNotification();
  const { host, port, database, user, password, engine } = connection;

  const openNotification = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message,
      description,
    });
  };

  const handleCreateDataSource = async () => {
    try {
      await mutateAsync({
        user,
        password,
        database,
        host,
        port,
        engine,
      });
      openNotification('success', 'Success', 'Data source created successfully');
    } catch (error) {
      console.log('===========================')
      console.log({ error })
      console.log('===========================')
      openNotification('error', 'Error', 'Data source creation failed');
    }
  }

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
      {contextHolder}
      <div className="pt-4">
        <Typography size={'lg'} weight={'md'} element={'p'} className="mb-3">
          Add new data source
        </Typography>
        <div className="row">
          <div>
            <div className="mb-2">
              <Input
                className="col-2 me-2"
                value={host}
                name="databaseHost"
                type="text"
                placeholder="Database Host"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    host: value,
                  })
                }
              />
              <Input
                className="col-2 me-2"
                value={port}
                name="databasePort"
                type="text"
                placeholder="Database Port"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    port: parseInt(value),
                  })
                }
              />
              <Input
                className="col-2"
                value={database}
                name="databaseName"
                type="text"
                placeholder="Database Name"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    database: value,
                  })
                }
              />
            </div>
            <div className="mb-2">
              <Input
                className="col-2 me-2"
                value={user}
                name="databaseUser"
                type="text"
                placeholder="Database User"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    user: value,
                  })
                }
              />
              <Input
                className="col-2"
                value={password}
                name="databasePassword"
                type="text"
                placeholder="Database Password"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    password: value,
                  })
                }
              />
              <Select
                value={engine}
                onChange={({ target: { value } }: { target: { value: string } }) =>
                  setConnection({
                    ...connection,
                    engine: value,
                  })
                }
                name="databaseEngine"
              >
                <option value="postgres">Postgres</option>
                <option value="mysql">MySQL</option>
              </Select>
            </div>
          </div>
          <div className="mt-2">
            <Button onClick={handleCreateDataSource}>
              Save data source
            </Button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <Typography size={'lg'} weight={'md'}>
          Existing data sources
        </Typography>
        <div>List goes here</div>
        <DataSources />
      </div>
    </Spin>
  );
};
