import React from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { fetchDashboardData } from '../../queries/fetchDashboardData/fetchDashboardData';
import { Typography } from '../../ui-kit/components/Typography/Typography';
import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { useParams } from 'react-router-dom';

type FieldType = {
  businessInfo: string;
  adhocPrompt: string;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

export const Dashboard = () => {
  const params = useParams();
  const connectionId = params.connectionId as string;
  const { data: widgets, isLoading, mutate } = fetchDashboardData();

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values);
    mutate({ connectionId, ...values });
  };

  return (
    <div className="p-4 d-flex row">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Business Information"
          name="businessInfo"
          rules={[{ required: true, message: 'Please input your business information!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item<FieldType>
          label="Prompt"
          name="adhocPrompt"
          rules={[{ required: true, message: 'Please input your prompt!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isLoading ? (
        <div>
          <Typography size={'lg'} weight={'rg'}>
            Loading dashboard data...
          </Typography>
        </div>
      ) : (
        widgets.map((widget) => {
          return (
            <div className="col-6 mt-4">
              <DashboardWidget {...widget} />
            </div>
          );
        })
      )}
    </div>
  );
};
